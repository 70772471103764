import React, {useState} from 'react';
import './Results.css'
import {useRecoilState} from "recoil";
import {countdownOnOptionsState, locatingState, resultsState, searchingState, searchState} from "../../States";
import moment from 'moment';
import Option from "./Option";
import {Icon} from "../../Icon/Icon";
import {Loader} from "../../Loader/Loader";
import useEffectOnce from "../../../utils/UseEffectOnce";
import {GpsLoader} from "../../GpsLoader/GpsLoader";
import {CentralButton} from "../../CentralButton/CentralButton";

let tickInterval;

export const Results = ({locator}) => {
    const [search, setSearch] = useRecoilState(searchState);
    const [searching] = useRecoilState(searchingState);
    const [locating] = useRecoilState(locatingState);
    const [results, setResults] = useRecoilState(resultsState);
    const [countdownOnOptions] = useRecoilState(countdownOnOptionsState);

    const [showOptions, setShowOptions] = useState(5);
    const [now, setNow] = useState(moment());

    useEffectOnce(() => {
        tickInterval = setInterval(() => {
            setNow(moment());
        }, 1000);
        return () => clearInterval(tickInterval);
    });
    const getLabel = (loc) => loc.gps ? 'GPS' : loc.label;

    const doSearch = () => {setSearch([...search])}

    const toggle = (key, i) => {
        const r = [...results];
        const result = r[i] = {...r[i]};
        result[key] = !result[key];
        setResults(r);
    };

    const renderOption = (option, i) => {
        return (
            <Option
                key={option.key}
                option={option}
                locator={locator}
                now={now}
                search={doSearch}
                toggleExpanded={() => toggle('expanded', i)}
                toggleCountdown={() => toggle('countDown', i)}
                countdownOnOptions={countdownOnOptions}
                searchFrom={(code, label) =>
                    setSearch([
                        {code, label},
                        search[1]
                    ])
                }
                toLabel={getLabel(search[1])}
            />
        );
    };

    const moreOptionsToShow = results && showOptions < results.length;

    const renderLoader = () => searching ? (<Loader overlay/>) :
        locating ? (<GpsLoader overlay/>) : <></>

    const content = !results && searching ? (
        <></>
    ) : !results || results.length === 0 ? searching ? null :(
        <div> No results.</div>
    ) : (
        <div className="results__optionList optionList">
            {results.slice(0, showOptions).map(renderOption)}
            {
                moreOptionsToShow ?
                    <button
                        className="results__moreOptionsButton"
                        onClick={() => setShowOptions(showOptions + 5)}
                    >
                        <Icon type={'arrow_down'}/>
                    </button> : ''
            }
        </div>
    );


    return (
        <div className={`results unselectable ${results && results.some(o => o.countDown) ? 'full' : ''} screen`}>
            {
                search ? (
                        <>
                            <div className="results__header">
                                {getLabel(search[0])} -> {getLabel(search[1])}
                            </div>
                            {
                                results ? (
                                    <>
                                        <div className="results__header">
                                            {getLabel(search[0])} -> {getLabel(search[1])}
                                        </div>
                                        {content}
                                        <CentralButton
                                            className={`${searching || locating ? ' active' : ''} ${results && results.some(o => o.countDown) ? ' noBg' : ''}`}
                                            onClick={() => searching || doSearch()}
                                        >
                                            <Icon type={'refresh'}/>
                                        </CentralButton>
                                    </>
                                ) : (<></>)
                            }
                        </>
                    ) :
                    (<div>Her er det ingenting før du gjør et søk.</div>)
            }
            {renderLoader()}
        </div>
    );
}
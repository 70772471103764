import React, {useState} from 'react';
import './MapView.css'
import Map from "./Map";
import {useRecoilState, useSetRecoilState} from "recoil";
import {editingNodeState, nodesState, fromNodeState, gpsState, searchState, screenDimState} from "../../States";
import useEffectPartialDeps from "../../../utils/UseEffectPartialDeps";
import {Point} from "leaflet/dist/leaflet-src.esm";
import {EditNode} from "../EditNode/EditNode";
import {useNavigate} from "react-router-dom";
import {HelpScreen} from "../../HelpScreen/HelpScreen";
import {getNextNodeAnchor} from "../../../utils/utils";

export const MapView = ({locator}) => {
    const [map, setMap] = useState(null);
    const [locations] = useRecoilState(nodesState);
    const [editingNode, setEditingNode] = useRecoilState(editingNodeState);
    const [screenDims] = useRecoilState(screenDimState);

    const [gpsMode] = useRecoilState(gpsState);
    const [fromNode, setFromNode] = useRecoilState(fromNodeState);
    const setSearch = useSetRecoilState(searchState);
    const [clickedNode, setClickedNode] = useState();
    const [longpressed, setLongpressed] = useState();

    const navigate = useNavigate();
    const goResults = () => navigate('/results');

    useEffectPartialDeps(() => {
        if (clickedNode) onNodeClick(clickedNode);
        setClickedNode(null);
    }, [clickedNode])

    useEffectPartialDeps(() => {
        if (!longpressed) return;
        const {lng, lat} = longpressed;
        setSearch([
            fromNode ?? {gps: true},
            {
                code: `coordinates: { longitude: ${lng} latitude: ${lat} }`,
                label: `Map coordinate`,
                name: `Map coordinate`,
                coordinate: [
                    lng,
                    lat,
                ]
            }
        ]);
        setLongpressed();
        setFromNode(null);
        goResults();
    }, [longpressed, fromNode]);

    const gpsSearch = node => {
        setSearch([{
            label: 'GPS',
            gps: true
        }, node]);
        goResults();
    }

    const onNodeClick = (node) => {
        if (gpsMode === 'startFromGps') {
            gpsSearch(node);
            return;
        }
        if (!fromNode) {
            setFromNode(node);
        } else if (fromNode.id === node.id)
            setFromNode(null);
        else {
            setSearch([fromNode, node]);
            setFromNode(null);
            goResults();
        }
    }

    useEffectPartialDeps(() => {
        if (editingNode) {

            let newEditingNode = structuredClone(editingNode);
            let changed = false;
            if (newEditingNode.coordinate === undefined) {
                let coordinate = null;
                let code = null;

                if (!map) {
                    console.log('missing map');
                } else {
                    const foo = map.containerPointToLatLng(new Point(
                        newEditingNode.anchor[1],
                        newEditingNode.anchor[0],
                    ))
                    coordinate = [
                        foo.lng,
                        foo.lat
                    ];
                    code = `coordinates: { longitude: ${foo.lng} latitude: ${foo.lat} }`
                }
                newEditingNode.coordinate = coordinate;
                newEditingNode.code = code;
                newEditingNode.locationFromMap = true;
                changed = true;
            }
            if (newEditingNode.replaceAnchors) {
                newEditingNode.anchor = getNextNodeAnchor(screenDims, locations)
                newEditingNode.replaceAnchors = false;
                changed = true;
            }
            if (changed)
                setEditingNode(newEditingNode);
        }
    }, [editingNode])

    if (editingNode && editingNode.coordinate)
        return <EditNode/>


    const unselectMarkerById = (id) => {
        if (fromNode && fromNode.id === id)
            setFromNode(null);
    }

    let markers = [];
    Object.values(locations).forEach((node, i) => {
        if (node.coordinate) {
            markers.push({
                ...node,
                coords: [...node.coordinate].reverse(),
                onClick: () => {
                    setClickedNode(node)
                }
            })
        }
    });

    let selectedIndex = null;
    if (fromNode) {
        selectedIndex = Object.keys(locations).findIndex(id => id === fromNode.id);
    }

    return (
        <div
            className="mapView"
        >
            <HelpScreen locations={Object.values(locations)} loc={'map'}/>
            <Map
                passMap={setMap}
                markers={markers}
                selectedIndex={selectedIndex}
                onNodeClick={setClickedNode}
                unselectMarkerById={unselectMarkerById}
                searchGpsToLatLng={setLongpressed}
                locator={locator}
                // triangle={triangles[trindex]}
            />
        </div>
    )

};